import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { Container, BannerSliderNav, BannerSliderCardWrapper } from './BannerSlider.styled';
import CardAdvertising, { CardAdvertisingProps } from '@/components/blocks/CardAdvertising/CardAdvertising';
import { SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { BaseOffsetsUI, IMetrika } from '@/interfaces';
import { BasicBackgrounds, TextColors, Colors } from '@/style/colors';
import { useRouter } from 'next/router';
import { METRIKS_SAMPLES } from '@/metriks/metriksSample';
import useIsIE11 from '@/hooks/useIsIE11';
import { BANNER_SLIDER_DELAY } from '@/helpers/bannerHelpers';

const SimpleSlider = dynamic(() => import('@/components/common/SimpleSlider/SimpleSlider'));

export interface BannerSliderProps extends BaseOffsetsUI, IMetrika {
    content: {
        items: CardAdvertisingProps[];
    };
    colorBgLink?: Colors;
    colorTextLink?: Colors;
    isHigher?: boolean;
    bigger?: boolean;
    textColor?: TextColors;
    onOpenModalMetrik?: {
        event: string;
        category: string;
        actions: [
            {
                key: string;
                value: boolean;
            },
            {
                key: string;
            },
            {
                key: string;
            }
        ];
    };
    bgColor?: BasicBackgrounds;
    isColorInversionButtons?: boolean;
}

const BannerSlider: React.FC<BannerSliderProps> = ({
    colorBgLink,
    colorTextLink,
    content,
    isHigher,
    topOffset,
    bottomOffset,
    textColor,
    metriksSample,
    metrika,
    onOpenModalMetrik,
    bgColor,
    bigger,
    metrikaAdv,
    isColorInversionButtons
}) => {
    const [swiper, setSwiper] = useState<SwiperCore>();
    const isIE = useIsIE11();
    const { pathname } = useRouter();

    return (
        <Container
            isHigher={isHigher}
            textColor={textColor}
            isIE={isIE}
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            bgColor={bgColor}
            bigger={bigger}
            colorBgLink={colorBgLink}
            colorTextLink={colorTextLink}
        >
            {content.items.length > 1 ? (
                <SimpleSlider
                    onSwiper={setSwiper}
                    swiperOptions={{
                        allowTouchMove: false,
                        grabCursor: false,
                        keyboard: false,
                        autoplay: {
                            delay: content.items[0]?.delay || BANNER_SLIDER_DELAY,
                            disableOnInteraction: false
                        },
                        loop: true,
                        spaceBetween: 8
                    }}
                    metrika={metrika}
                >
                    {content.items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <CardAdvertising
                                {...item}
                                metriksSample={metriksSample}
                                buttonTextMetrik={METRIKS_SAMPLES[pathname]?.bannerButtonText && true}
                                metrikaAdv={metrikaAdv}
                            />
                        </SwiperSlide>
                    ))}

                    <BannerSliderNav swiper={swiper} />
                </SimpleSlider>
            ) : (
                <BannerSliderCardWrapper>
                    <CardAdvertising
                        {...content.items[0]}
                        isColorInversionButtons={isColorInversionButtons}
                        metriksSample={metriksSample}
                        metrika={metrika}
                        onOpenModalMetrik={onOpenModalMetrik}
                        buttonTextMetrik={METRIKS_SAMPLES[pathname]?.bannerButtonText && true}
                        metrikaAdv={metrikaAdv}
                    />
                </BannerSliderCardWrapper>
            )}
        </Container>
    );
};

export default BannerSlider;
