import styled from 'styled-components';
import SliderNavigation from '@/components/common/SliderNavigation/SliderNavigation';
import { color, offset, mediaBreakpointDown, mediaBreakpointUp, vw, sideOffsets, allBreakpointValue } from '@/style/mixins';
import { Container as SimpleSliderContainer, SimpleSliderSwiper } from '@/components/common/SimpleSlider/SimpleSlider.styled';
import { Container as CardAdvertising, CardAdvertisingText } from '@/components/blocks/CardAdvertising/CardAdvertising.styled';
import { Container as Buttons, ButtonsLink } from '@/components/blocks/Buttons/Buttons.styled';
import { BaseOffsetsUI } from '@/interfaces';
import { BasicBackgrounds, TextColors, Colors } from '@/style/colors';

export const Container = styled.div<
    BaseOffsetsUI & {
        colorBgLink?: Colors;
        colorTextLink?: Colors;
        isHigher?: boolean;
        isIE?: boolean;
        textColor?: TextColors;
        bgColor?: BasicBackgrounds;
        bigger?: boolean;
    }
>`
    position: relative;
    overflow: hidden;
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('xxxl', 'padding-top'))};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    background-color: ${({ bgColor }) => bgColor && color(bgColor)};

    ${({ colorBgLink }) =>
        colorBgLink &&
        `
        ${ButtonsLink} {
            background-color: ${color(colorBgLink)};
            border-color: ${color(colorBgLink)};
        }
    `};

    ${({ colorTextLink }) =>
        colorTextLink &&
        `
        ${ButtonsLink} {
            color: ${color(colorTextLink)};
        }
    `};

    ${CardAdvertising} {
        z-index: 1;

        ${({ bigger }) =>
            bigger ? allBreakpointValue('min-height', 380, 285, 253, 480, 555) : allBreakpointValue('min-height', 380, 285, 253, 480, 348)}

        color: ${({ textColor }) => (textColor ? color(textColor) : color('white'))};
        background-color: ${({ theme }) => theme && color(theme.main.background)};

        ${({ isHigher }) =>
            isHigher &&
            `
            ${allBreakpointValue('min-height', 420, 343, 305, 238, 400)}

            @media (min-width: 520px) and (max-width: 767px) {
                min-height: 80vw;
            }

            ${Buttons} {
                display: block;
                margin-top: 24px;

                ${mediaBreakpointUp('md')} {
                    display: flex;
                    margin-top: auto;
                }
            }
        `};

        ${({ isHigher, isIE }) =>
            isHigher &&
            isIE &&
            `
            ${Buttons} {
                position: absolute;
            }
        `};

        ${mediaBreakpointDown('md')} {
            min-height: auto;
        }
    }

    ${CardAdvertisingText} {
        ${mediaBreakpointDown('md')} {
            flex-grow: 1;
        }
    }

    ${SimpleSliderContainer} {
        padding-top: 0;
        padding-bottom: 0;
    }

    ${SimpleSliderSwiper} {
        ${mediaBreakpointDown('xl')} {
            overflow: visible;
        }

        .swiper-slide {
            width: 314px;

            ${mediaBreakpointUp('md')} {
                width: ${vw(680, 'md')};
            }

            ${mediaBreakpointUp('xl')} {
                width: 100%;
            }
        }
    }
`;

export const BannerSliderNav = styled(SliderNavigation)`
    position: absolute;
    z-index: 1;
    color: ${color('white')};
    ${offset('m', 'bottom')};
    ${offset('m', 'right')};
`;

export const BannerSliderCardWrapper = styled.div`
    ${sideOffsets()};
`;
