import styled, { css } from 'styled-components';
import Picture from '@/components/common/Picture/Picture';
import RectLink from '@/components/ui/RectLink/RectLink';
import { color, offset, allBreakpointValue, mediaBreakpointUp, font, hover, vw, mediaBreakpointDown } from '@/style/mixins';
import { Container as Buttons, ButtonsLink } from '@/components/blocks/Buttons/Buttons.styled';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { TextColors } from '@/style/colors';
import { remFluid } from '@/style/typography';

export const Container = styled.div<{ textColor?: TextColors; isColorInversionButtons?: boolean }>`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    ${offset('m', 'padding')};
    height: 100%;

    ${({ textColor }) =>
        textColor
            ? css`
                  color: ${color(textColor)}!important;
              `
            : css`
                  color: ${color('white')};
              `}

    ${Buttons} {
        margin-top: 24px;

        ._is-ie & {
            z-index: 1;
            ${offset('m', 'bottom')};
            ${offset('m', 'left')};
            ${offset('m', 'right')};

            ${mediaBreakpointUp('xl')} {
                position: absolute;
            }
        }

        ${mediaBreakpointUp('md')} {
            margin-top: 24px;
            width: fit-content;

            a {
                margin-right: 16px;
            }
        }

        ${mediaBreakpointUp('xl')} {
            flex-direction: column;
            margin-top: auto;
        }

        & > * + * {
            &:not(:last-child) {
                margin-bottom: 16px;
            }

            ${mediaBreakpointUp('md')} {
                margin-top: 0;
            }
            ${mediaBreakpointUp('xl')} {
                ${offset('xxxs', 'margin-top')}
            }
        }
    }

    ${ButtonsLink} {
        &:nth-child(2) {
            ${({ isColorInversionButtons }) =>
                isColorInversionButtons &&
                `
                background-color: ${color('white')};
                border-color: ${color('white')};
                color: ${color('black')};

                ${mediaBreakpointUp('md')} {
                    margin-top: 0px;
                    ${offset('xl', 'margin-left')};
                }
            `}
        }
    }

    ${ModalBtn} {
        align-self: flex-start;
        white-space: nowrap;
        background-color: ${color('white')};
        border-color: ${color('white')};

        &:not(:last-child) {
            ${offset('s', 'margin-right')};
        }

        &:nth-child(1) {
            ${({ isColorInversionButtons }) =>
                isColorInversionButtons &&
                `
                background-color: ${color('black')};
                border-color: ${color('black')};
                color: ${color('white')};
            `}
        }

        &:nth-child(2) {
            background-color: unset;
            border-color: ${color('white')};
            color: ${color('white')};

            ${({ isColorInversionButtons }) =>
                isColorInversionButtons &&
                `
                background-color: ${color('white')};
                border-color: ${color('white')};
                color: ${color('black')};
            `}

            ${hover(`
            span {
                opacity: 0.5;
            }
        `)};
        }
    }
`;

export const Wrap = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    ${offset('m', 'margin-bottom')};
    z-index: 1;

    span {
        ${font('text2-l')};
        margin-left: 20px;
    }

    ${mediaBreakpointUp('md')} {
        position: static;
    }
`;

export const CardAdvertisingTitle = styled.p`
    ${font('h4-b')};

    ._is-ie & {
        z-index: 1;
    }

    ${mediaBreakpointUp('xl')} {
        max-width: 66.7%;
    }
`;

export const CardAdvertisingText = styled.div`
    ${font('text1-l')};
    ${offset('xs', 'margin-top')};

    ${mediaBreakpointUp('xl')} {
        margin-bottom: 20px;
        max-width: 66.7%;
    }

    ._is-ie & {
        z-index: 1;
        ${allBreakpointValue('margin-bottom', 90, 70, 60)};
    }
`;

export const CardAdvertisingInfo = styled.div<{ blackTextColor?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: ${vw(168, 'xs')};
    ${remFluid('font-size', 8, 11, 11, 11, 11, 16)};

    ${({ blackTextColor }) =>
        blackTextColor
            ? `
                text-shadow: 0 0 6px ${color('white')};
                color: ${color('black')};
            `
            : `
                text-shadow: 0 0 6px ${color('black')};
                color: ${color('white')};
            `}
    opacity: 0.6;

    ${mediaBreakpointDown('md')} {
        margin-left: ${vw(10, 'xs')};
        padding-bottom: ${vw(10, 'xs')};
        height: ${vw(136, 'xs')};
        z-index: 100;
    }

    ${mediaBreakpointUp('md')} {
        top: unset;
        ${offset('m', 'left')};
        ${offset('m', 'bottom')};
        width: 232px;
    }

    ${mediaBreakpointUp('xl')} {
        ${offset('m', 'top')};
        ${offset('m', 'right')};
        left: unset;
        bottom: unset;
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(232, 'fhd')};
    }
`;

export const CardAdvertisingBackground = styled(Picture)`
    order: -1;
    width: calc(100% + 2 * ${vw(20, 'xs')});
    height: ${vw(136, 'xs')};
    top: -${vw(20, 'xs')};
    left: -${vw(20, 'xs')};
    right: -${vw(20, 'xs')};
    bottom: -${vw(20, 'xs')};
    overflow: hidden;
    border-radius: 0 0 8px 8px;

    ${mediaBreakpointUp('md')} {
        order: initial;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;

        ._is-ie & {
            z-index: 0;
        }
    }
`;

export const CardAdvertisingBackgroundLink = styled(RectLink)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    ._is-ie & {
        z-index: 0;
    }
`;
