import React from 'react';
import { Container } from './SliderNavigation.styled';
import Swiper from 'swiper';
import Arrow from '@/icons/arrow.svg';

export interface SliderNavigationProps {
    swiper?: Swiper | (Swiper | undefined)[];
    position?: string;
    className?: string;
}

const SliderNavigation: React.FC<SliderNavigationProps> = ({ swiper, position, className }) => {
    const swiperIsArray = Array.isArray(swiper);

    return (
        <Container className={className}>
            <div
                className={`swiper-button prev ${position === 'beginning' && !swiperIsArray && !swiper?.loopedSlides ? 'disabled' : ''}`}
                onClick={() => {
                    if (swiperIsArray) {
                        swiper.forEach((instance) => instance && instance.slidePrev());
                    } else {
                        swiper?.slidePrev();
                    }
                }}
            >
                <Arrow />
            </div>
            <div
                className={`swiper-button next ${position === 'end' && !swiperIsArray && !swiper?.loopedSlides ? 'disabled' : ''}`}
                onClick={() => {
                    if (swiperIsArray) {
                        swiper.forEach((instance) => instance && instance.slideNext());
                    } else {
                        swiper?.slideNext();
                    }
                }}
            >
                <Arrow />
            </div>
        </Container>
    );
};

export default SliderNavigation;
