import { IBannerAdvertisingInfo, IMetrika, LinkI, ModalBtnI } from '@/interfaces';
import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import {
    Container,
    CardAdvertisingTitle,
    CardAdvertisingText,
    CardAdvertisingBackground,
    CardAdvertisingBackgroundLink,
    Wrap,
    CardAdvertisingInfo
} from './CardAdvertising.styled';
import { PictureType } from '@/components/common/Picture/Picture';
import { clearFromNBSP, sendMetrik } from '@/helpers';
import { TextColors } from '@/style/colors';
import { METRIKS_SAMPLES, reduceActions } from '@/metriks/metriksSample';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import Tag from '@/components/ui/Tag/Tag';
import useTranslation from '@/hooks/useTranslation';
import dayjs from 'dayjs';

const Buttons = dynamic(() => import('@/components/blocks/Buttons/Buttons'));

export interface CardAdvertisingProps extends IMetrika {
    title: string;
    text?: string;
    tag?: string;
    date?: string;
    price?: string | number;
    background: PictureType;
    textColor?: TextColors;
    backgroundLink?: {
        href: string;
        isForeign: boolean;
    };
    links?: LinkI[];
    modal?: ModalBtnI[];
    scrollButtons?: {
        text: string;
        scrollToSelector?: string;
    }[];
    onOpenModalMetrik?: {
        event: string;
        category: string;
        actions: [
            {
                key: string;
                value: boolean;
            },
            {
                key: string;
            },
            {
                key: string;
            }
        ];
    };
    delay?: number;
    buttonTextMetrik?: boolean;
    isColorInversionButtons?: boolean;
    advertising?: IBannerAdvertisingInfo;
}

const CardAdvertising: React.FC<CardAdvertisingProps> = ({
    background,
    title,
    tag,
    date,
    price,
    text,
    backgroundLink,
    links,
    scrollButtons,
    textColor,
    modal,
    metriksSample,
    metrika,
    onOpenModalMetrik,
    buttonTextMetrik,
    isColorInversionButtons,
    metrikaAdv,
    advertising
}) => {
    const { t } = useTranslation('common');
    const { pathname } = useRouter();
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView && metrikaAdv) {
            sendMetrik('banners-impressions', 'banners', { 'banner-id-impression': clearFromNBSP(title) }, 'impressions');
        }
    }, [inView]);
    const submitMetriks = () => {
        if (!METRIKS_SAMPLES[pathname]?.cardAdvertising) return;
        const reducedActions = reduceActions(METRIKS_SAMPLES[pathname]?.cardAdvertising);

        sendMetrik(METRIKS_SAMPLES[pathname]?.cardAdvertising?.event, METRIKS_SAMPLES[pathname]?.cardAdvertising?.category, {
            ...reducedActions
        });
    };

    return (
        <Container textColor={textColor} isColorInversionButtons={isColorInversionButtons} ref={ref}>
            {(tag || date) && (
                <Wrap>
                    {tag && <Tag>{tag}</Tag>}
                    {date && (
                        <span>
                            {dayjs(date, 'DD.MM.YYYY').format('DD MMMM YYYY')}{' '}
                            {!!price && price !== '0'
                                ? typeof price === 'number'
                                    ? `| ${price} ₽`
                                    : `${new Intl.NumberFormat('ru', { style: 'decimal', currency: 'RUB' }).format(
                                          parseFloat(`${price}`)
                                      )} ₽`
                                : `| ${t('free')}`}
                        </span>
                    )}
                </Wrap>
            )}

            <CardAdvertisingTitle dangerouslySetInnerHTML={{ __html: title }} />
            {text && <CardAdvertisingText dangerouslySetInnerHTML={{ __html: text }} />}
            {advertising && (
                <CardAdvertisingInfo blackTextColor={advertising.blackTextColor}>
                    <p dangerouslySetInnerHTML={{ __html: advertising.text }} />
                    <div>
                        {advertising.ogrn && (
                            <span>
                                {t('ogrn')} {advertising.ogrn},
                            </span>
                        )}
                        {advertising.inn && (
                            <span>
                                {' '}
                                {t('inn')} {advertising.inn}
                            </span>
                        )}
                        {advertising.id && <span> {advertising.id}</span>}
                    </div>
                </CardAdvertisingInfo>
            )}

            {modal && links ? (
                <Buttons
                    modals={modal}
                    links={links}
                    haveBg
                    metriksSample={metriksSample}
                    newMetrikaHandler={submitMetriks}
                    metrika={
                        (metrika && metrika) ||
                        (metriksSample &&
                            (() =>
                                sendMetrik(
                                    metriksSample.event,
                                    metriksSample.category,
                                    { [metriksSample.action]: clearFromNBSP(title) },
                                    metriksSample.gaAction
                                )))
                    }
                />
            ) : (
                (links && (
                    <Buttons
                        links={links}
                        haveBg
                        metrika={
                            (metrika && metrika) ||
                            (metriksSample &&
                                (() =>
                                    sendMetrik(
                                        metriksSample.event,
                                        metriksSample.category,
                                        { [metriksSample.action]: clearFromNBSP(title) },
                                        metriksSample.gaAction
                                    )))
                        }
                        buttonTextMetrik={buttonTextMetrik}
                    />
                )) ||
                (modal && <Buttons modals={modal} metrika={metrika} onOpenModalMetrik={onOpenModalMetrik} />)
            )}
            {scrollButtons && <Buttons scrollButtons={scrollButtons} metrika={metrika} onOpenModalMetrik={onOpenModalMetrik} haveBg />}

            {backgroundLink?.href && links?.length === 0 && <CardAdvertisingBackgroundLink {...backgroundLink} />}
            <CardAdvertisingBackground {...background} alt={background?.alt || title} objectFit="cover" />
        </Container>
    );
};

export default CardAdvertising;
