import styled from 'styled-components';
import { allBreakpointValue, mediaBreakpointUp } from '@/style/mixins';

export const Container = styled.div`
    display: none;

    ${mediaBreakpointUp('xl')} {
        display: flex;
        order: -1;
        align-items: center;
    }

    .swiper-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;

        &.disabled {
            opacity: 0.3;
            cursor: default;
        }

        &.prev {
            &:hover:not(.disabled) {
                svg {
                    transform: translateX(-5px) rotate(-180deg);
                }
            }

            svg {
                transform: rotate(-180deg);
            }
        }

        &.next {
            ${allBreakpointValue('margin-left', 20, 15, 10)};

            &:hover:not(.disabled) {
                svg {
                    transform: translateX(5px);
                }
            }
        }

        svg {
            display: block;
            ${allBreakpointValue('width', 18, 13.5, 12)};
            ${allBreakpointValue('height', 18, 13.5, 12)};
            stroke: currentColor;
            transition: transform 0.3s ease-in-out;
        }
    }
`;
