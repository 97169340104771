import styled from 'styled-components';
import RectLink from '@/components/ui/RectLink/RectLink';
import { color, offset, mediaBreakpointDown, hover, sideOffsets } from '@/style/mixins';
import { BaseOffsetsUI } from '@/interfaces';
import { BasicBackgrounds } from '@/style/colors';

export const Container = styled.div<BaseOffsetsUI & { hasSideOffsets?: boolean; bgColor?: BasicBackgrounds }>`
    display: flex;
    flex-direction: row;

    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    ${({ hasSideOffsets }) => hasSideOffsets && sideOffsets('padding')};
    background-color: ${({ bgColor }) => bgColor && color(bgColor)};

    ${mediaBreakpointDown('md')} {
        flex-direction: column;

        & > * {
            width: 100%;
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    ._is-ios & {
        margin-bottom: 1px;
    }
`;

export const ButtonsLink = styled(RectLink)<{ bg?: boolean }>`
    align-self: flex-start;

    &:not(:last-child) {
        ${offset('s', 'margin-right')};
    }

    &:nth-child(1) {
        background-color: ${({ bg }) => bg && color('white')};
        border-color: ${({ bg }) => bg && color('white')};
    }

    &:nth-child(2) {
        color: ${({ bg }) => bg && color('white')};
        border-color: ${({ bg }) => bg && color('white')};

        ${hover(`
            span {
                opacity: 0.5;
            }
        `)};
    }
`;
